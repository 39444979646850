import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js'

class InterconnectionInfoCollectionStatostics extends HTTP {
  getSchoolCollectRecordList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_COLLECT_RECORD_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  updateStatus(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.UPDATE_SCHOOL_COLLECT_STATUS,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getAnwer(id) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_COLLECT_ANWER + id,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getSchoolCollectTeacherStatisticsList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_COLLECT_TEACHER_STATISTICS_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getSchoolCollectDetailsList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_COLLECT_DETAILS_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  exportSchoolCollectTeacherStatistics (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.EXPORT_SCHOOL_COLLECT_TEACHER_STATISTICS,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

}

export { InterconnectionInfoCollectionStatostics }
