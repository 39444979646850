import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js'

class InterconnectionInfoCollection extends HTTP {
  getSchoolCollectFormList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_COLLECT_FORM_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  releaseSchoolCollectForm (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.RELEASE_SCHOOL_COLLECT_FORM,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  finishSchoolCollectForm (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.FINISH_SCHOOL_COLLECT_FORM,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  delayedSchoolCollectForm (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.DELAYED_SCHOOL_COLLECT_FORM,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  deletedSchoolCollectForm (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.DELETE_SCHOOL_COLLECT_FORM,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getSchoolCollectFormInfo (id) {
    return new Promise((resolve, reject) => {
      this.axiosGet({
        url: config.API.GET_SCHOOL_COLLECT_FORM_INFO+id,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
}

export { InterconnectionInfoCollection }
