import {HTTP} from "@/libs/https.js";
import config from "@/libs/config.js";

class LoginModel extends HTTP {
    // 通过schoolId获取登录页模板详情
    getLoginPageTemplateDetailBySchoolId(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_LOGIN_PAGE_TEMPLATE_DETAIL_BY_SCHOOL_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    // 发短信验证码
    sendSms(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SEND_SMS,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }

    // 登录整合(新登录)
    loginEntrance(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.LOGIN_ENTRANCE,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }

    // 账号密码登录(旧登录)
    login(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_USER_TOKEN,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }

    getProjectInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PROJECT_INFO,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }

    getUserInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.GET_USER_INFO,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }

    logout(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.AUTH_SC_LOGOUT,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }

    isReg(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.MOBILE_IS_REGISTER + '/' + data.phoneNumber + '/teacher',
                data: {},
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }
    getEducationAutoLogin(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDUCATION_AUTO_LOGIN,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    }
}

export {LoginModel};
