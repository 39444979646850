import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class CustodialServicesModel extends HTTP {
    getHostingServicesList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.HOSTING_SERVICES_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    hostingServicesSave(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.HOSTING_SERVICES_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    setHostingServicesDel(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.HOSTING_SERVICES_DEL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    hostingServicesCheckStu(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.HOSTING_SERVICES_CHECK_STU,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    addHostingStu(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ADD_HOSTING_STU,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    importHostingStu(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.IMPORT_HOSTING_STU,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    downloadExcelError(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DOWNLOAD_EXCEL_ERROR,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getClassDropDownList(data) {
		return new Promise((resolve, reject) => {
			this.axiosPostArray({
				url: config.API.GET_CLASS_DROP_DOWN_LIST,
				data: data,
				header: "application/json",
				success(data) {
					resolve({
						data,
						status: 0,
					});
				},
				error(error) {
					resolve({
						error,
						status: -1,
					});
				},
			});
		});
	}

	getYearDropDownList(data) {
		return new Promise((resolve, reject) => {
			this.axiosPost({
				url: config.API.GET_YEAR_DROP_DOWN_LIST,
				data: data,
				header: "application/json",
				success(data) {
					resolve({
						data,
						status: 0,
					});
				},
				error(error) {
					resolve({
						error,
						status: -1,
					});
				},
			});
		});
	}

    getDepositStudentList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DEPOSIT_STUDENT_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getImportTemplate(data){
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.DOWNLOAD_EXCEL_MODEL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    deteleDepositStudent(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_HOSTING_STU,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAddClassesList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CLASSES_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getStudentList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_STUDENT_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    
    importDepositUpload(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.IMPORT_DEPOSITUPLOAD,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

}

export { CustodialServicesModel };
