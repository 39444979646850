import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js'

class LoginPageManagementModel extends HTTP {
    // 获取登录页模板列表
    getLoginPageTemplateList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_LOGIN_PAGE_TEMPLATE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    // 启用登录页模板
    setLoginPageTemplateEnabled(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_LOGIN_PAGE_TEMPLATE_ENABLED,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    // 获取登录页模板详情
    getLoginPageTemplateDetail(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_LOGIN_PAGE_TEMPLATE_DETAIL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    // 编辑登录页模板
    editLoginPageTemplate(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDIT_LOGIN_PAGE_TEMPLATE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    // 复制登录页模板
    copyLoginPageTemplate(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.COPY_LOGIN_PAGE_TEMPLATE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    // 删除登录页模板
    deleteLoginPageTemplate(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_LOGIN_PAGE_TEMPLATE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { LoginPageManagementModel }
