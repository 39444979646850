import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js'

class DeviceManagementModel extends HTTP {
  getEquipmentList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_EQUIPMENT_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  //设备管理-设备管理列表
  getschoolEquipmentList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_EQUIPMENT_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  
  getSchoolAddressListe(data){
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_ADDRESS_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

//交易流水列表
getvendingRecord(data){
  return new Promise((resolve, reject) => {
    this.axiosPost({
      url: config.API.GET_VENDING_RECORD_LIST,
      data: data,
      header: 'application/json',
      success (data) {
        resolve({
          data,
          status: 0
        });
      },
      error (error) {
        resolve({
          error,
          status: - 1
        })
      }
    });
  });
}
// 交易流水统计
    vendingRecordStatistics(data){
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.VENDING_RECORD_STATISTICS,
          data: data,
          header: 'application/json',
          success (data) {
            resolve({
              data,
              status: 0
            });
          },
          error (error) {
            resolve({
              error,
              status: - 1
            })
          }
        });
      });
    }
// 获取地址列表
    
    getAddressList(data){
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.GET_ADDRESS_LIST,
          data: data,
          header: 'application/json',
          success (data) {
            resolve({
              data,
              status: 0
            });
          },
          error (error) {
            resolve({
              error,
              status: - 1
            })
          }
        });
      });
    }

   
    //删除地址
    deleteAddress(data){
      return new Promise((resolve, reject) => {
        this.axiosPostArray({
          url: config.API.DELETE_ADDRESS,
          data: data,
          header: 'application/json',
          success (data) {
            resolve({
              data,
              status: 0
            });
          },
          error (error) {
            resolve({
              error,
              status: - 1
            })
          }
        });
      });
    }

    //保存、修改地址
    saveAddress(data){
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.SAVE_ADDRESS,
          data: data,
          header: 'application/json',
          success (data) {
            resolve({
              data,
              status: 0
            });
          },
          error (error) {
            resolve({
              error,
              status: - 1
            })
          }
        });
      });
    }

    
    saveVending(data){
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.VENDING_SAVE,
          data: data,
          header: 'application/json',
          success (data) {
            resolve({
              data,
              status: 0
            });
          },
          error (error) {
            resolve({
              error,
              status: - 1
            })
          }
        });
      });
    }

  equipmentScrap (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.EQUIPMENT_SCRAP,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  equipmentUnbound (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.EQUIPMENT_UNBOUND,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  equipmentInsert (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.EQUIPMENT_INSERT,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getSchoolEquipmentStatistics (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GTE_SCHOOL_EQUIPMENT_STATISTICS,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  getEquipmentStatistics (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_EQUIPMENT_STATISTICS,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  
  getEquipmentInfo(data){
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_EQUMENT_INFO,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  getEquipmentDetails (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_EQUIPMENT_DETAILS,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  equipmentExport (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.EQUIPMENT_EXPORT,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  equipmentUpload (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.EQUIPMENT_UPLOAD,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  equipmentTemplate (data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: config.API.EQUIPMENT_TEMPLATE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
    getSchoolAddressList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EQUIPMENT_SCHOOL_ADDRESS_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    
    getSchoolEquipment(data){
      return new Promise((resolve, reject) => {
        this.axiosPost({
            url: config.API.GET_SCHOOL_EQUIPMENT,
            data: data,
            header: 'application/json',
            success(data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error(error) {
                resolve({
                    error,
                    status: -1
                })
            }
        });
    });
    }
    saveSchoolAddress(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_ADDRESS_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    deleteSchoolAddress(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SCHOOL_ADDRESS_DELETE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    verifyEquipmentCode(data) {
      return new Promise((resolve, reject) => {
          this.axiosPostArray({
              url: config.API.EQUIPMENT_VERIFY_EQUIPMENTCODE,
              data: data,
              header: 'application/json',
              success(data) {
                  resolve({
                      data,
                      status: 0
                  });
              },
              error(error) {
                  resolve({
                      error,
                      status: -1
                  })
              }
          });
      });
  }
  // 导入设备
  importEquipment(data) {
    return new Promise((resolve, reject) => {
        this.axiosFile({
            url: config.API.EQUIPMENT_MACHINE_UPLOAD,
            data: data,
            header: 'application/json',
            success(data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error(error) {
                resolve({
                    error,
                    status: - 1
                })
            }
        });
    });
  }
}

export { DeviceManagementModel }
